// Viewport single column block, centered horizontally and vertically
.block-viewport {
    height: 100vh;
    @extend %flexbox-center-child;
    > .content-wrapper {
        max-width: 500px;
        width: 100%;
        margin: auto;
        padding: 32px;
    }

    // Inverted theme primary option
    &.inverted-theme-primary {
        background: $color-inverted-background url('../img/blue-bg.jpg');
        color: $color-inverted-text;
        h1 {
            color: $color-inverted-text;
        }
        h2 {
            color: $color-inverted-text;
        }

        a {
            color: $color-inverted-text;
            &:hover {
                opacity: 1.0;
            }
        }

        input, textarea, select{
            border-bottom: 1px solid $color-primary-light;
            color: $color-inverted-text;
        }
    }
}
