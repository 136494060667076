.animation {
      // Initial State (before web fonts are loaded, before animation)

      &.first,
      &.second,
      &.third,
      &.fourth {
        @extend .animated;

        opacity: 0; /* stylelint-disable-line declaration-empty-line-before */
        transform: translateY(40px);
      }

      &.fade-in {
        @extend .animated;
        opacity: 0;
      }

      // Activated State (web fonts are loaded & "activate-animation" class exists)
      .wf-active &.animate,
      .wf-inactive &.animate {
        @extend .fadeIn;

        transition-timing-function: ease;
        transform: translateY(0);

        &.first {
          transition-duration: 1s;
          animation-delay: 0.2s;
        }

        &.second {
          transition-duration: 2s;
          animation-delay: 0.4s;
        }

        &.third {
          transition-duration: 3s;
          animation-delay: 0.6s;
        }

        &.fourth {
          transition-duration: 4s;
          animation-delay: 0.8s;
        }

        &.fade-in {
          transition-duration: 1s;
          animation-delay: 0.2s;
        }
      }





    }