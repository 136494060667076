.video-banner {
    width: inherit;
    height: inherit;
    min-height: inherit;
    max-height: inherit;
    overflow: hidden;
    position: relative;

    .background {
        width: inherit;
        height: inherit;
        min-height: inherit;
        max-height: inherit;
        
        > .video, > .image {
            width: 100%;
        }

        > .video {
            display: block;
        }

        > .image {
            width: 100%;
            min-height: 600px;
            display: none;
            background-position: top center;
            background-repeat: no-repeat;
            background-size: cover;
        }
    }

    .content {
        position:absolute;
        top:0;
        left:0;
        right:0;
        bottom:0;

        > .centralized {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            @extend %flexbox-center-child;

            > * {
                text-align: center;

                h1 {
                    margin: 0;
                    color: $color-white;
                    font-size: 3.0rem;

                    span {
                        display: block;
                        font-size: 16.0rem;
                        font-weight: 900;
                        line-height: 1.0;
                    }
                }

                .call-to-action {
                    display: inline-block;
                }
            }
        }

        > .scroll-down {
            position: absolute;
            bottom: 16px;
            left: 0;
            right: 0;

            > .animated-icon {
                margin: 0 auto;
                width: 100px;
                height: 100px;
                background: url('../img/icon-scroll-down.svg');
                transform: scale(1);
                animation: play 1s steps(6) infinite;

                @keyframes play {
                    from { background-position: 0px; }
                    to { background-position: -600px; }
                }
            }
        }
    }

    // Class modifiers

    &.offset-top-bar {
        .content {
            > .centralized {
                top: 59px;
            }
        }
    }

    &.animation {
        // Initial state (before web fonts are loaded, before the animation)
        & {
            h1                  { @extend .animated; opacity: 0; }
            h1 span             { @extend .animated; opacity: 0; }
            .call-to-action     { @extend .animated; opacity: 0; }
            .scroll-down        { @extend .animated; opacity: 0; }
        }

        // Activated state (when web fonts are loaded, "animate" class added)
        .wf-active &.animate, .wf-inactive &.animate {
            h1                  { @extend .fadeIn; animation-delay: 0.1s; }
            h1 span             { @extend .fadeIn; animation-delay: 0.6s; }
            .call-to-action     { @extend .fadeIn; animation-delay: 1.2s; }
            .scroll-down        { @extend .fadeIn; animation-delay: 1.8s; }
        }
    }

    @mixin respond-1 {
        .background {
            > .video {
                display: none;
            }

            > .image {
                display: block;
            }
        }
    }

    @mixin respond-2 {
        .background {
            > .image {
                min-height: 300px;
            }
        }

        .content {
            > .centralized {
                > * {
                    h1 {
                        font-size: 2.0rem;
                        font-weight: 300;
                        span {
                            font-size: 10.0rem;
                        }
                    }
                    .call-to-action {
                        display: none;
                    }
                }
            }

            > .scroll-down {
                display: none;
            }
        }
    }

    // Responsive breakpoints
    @include mq($until: mq-10)      { @include respond-1; }
    @include mq($until: mq-4)       { @include respond-2; }
}
