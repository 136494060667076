figure {
    position: relative;
    margin-left: 0;
    margin-right: 0;
    
    figcaption {
        position: absolute;
        right: -30px;
        bottom: -30px;
        padding: 40px;
        max-width: 300px;
        background-color: $color-semi-dark-grey;
        color: $color-inverted-text;
        text-align: center;
    }
    
    img {
        margin: 0 0 30px;
        width: 100%;
    }
    
    // Responsive methods
    @mixin respond-1 {
        img {
            margin-bottom: 0;
        }
        
        figcaption {
            position: relative;
            right: auto;
            bottom: auto;
            padding: 20px;
            max-width: none;
        }
    }
    
    // Responsive breakpoints
    @include mq($until: mq-10)      { @include respond-1; }
}
