.filter {
  list-style: none;
  position: relative;
  text-align: center;
  margin-bottom: 20px;
  justify-content: center;
  display: flex;

  li {
    border: 1px solid $color-light-grey;
    padding: 20px 40px;
    margin: 0;

    &.active {
      background-color: $color-red;
      a {
        color: $color-inverted-text;
      }
    }
  }

  li:first-child {
    border-radius: 50px 0 0 50px;
  }

  li:last-child {
    border-radius: 0 50px 50px 0;
  }
}
