.carousel {
  position: relative;

  .content-wrapper {
    position: relative;
    margin: auto;
    width: inherit;
    height: inherit;
    min-height: inherit;
    max-height: inherit;

    &.offset-top-bar {
      padding-top: 113px;
    }

    .swiper-container {
      width: inherit;
      height: inherit;
      min-height: inherit;
      max-height: inherit;

      .swiper-wrapper {
        z-index: 0;
        width: inherit;
        height: inherit;
        min-height: inherit;
        max-height: inherit;
        box-sizing: border-box;

        .swiper-slide {
          min-height: inherit;
          max-height: inherit;
        }
      }

      .pagination {
        z-index: 1;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 24px;
        text-align: center;

        .swiper-pagination-bullet {
          background: $color-general-background;
          width: 16px;
          height: 16px;
        }

        .swiper-pagination-bullet-active {
          background: $color-primary;
        }
      }
    }
  }

  .navigation {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    > a {
      z-index: 5;
      position: absolute;
      top: 50%;
      margin-top: -30px;
      font-size: 60px;
      background-color: transparent;
      color: $color-inverted-text;
      opacity: 0.8;
      @extend .bs-icon;

      &.button-previous {
        left: 24px;
        @extend .bs-icon-arrow09-left;
      }
      &.button-next {
        right: 24px;
        @extend .bs-icon-arrow09-right;
      }
    }
  }

  // Full viewport mode
  &.hero {
    max-height: calc(100vh);
  }

  @mixin respond-1 {
    .navigation {
      display: none;
    }

    .content-wrapper {
      &.offset-top-bar {
        padding-top: 80px;
      }
    }
  }

  // Responsive breakpoints
  @include mq($until: mq-10) {
    @include respond-1;
  }
}

// Sample carousel modes that may be applied for different scenarios
/*
.cap-width-at-container .carousel {
    margin: auto;
    max-width:980px;
}

.cap-width-at-container-and-navigation-outside .carousel {
    margin: auto;
    max-width:1280px;
    
    > .content-wrapper {
        margin: 0 108px;
    }
}
*/
