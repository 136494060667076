.key-points {
  @extend .standard-vertical-gap;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  list-style: none;

  > li {
    flex: 1 1 auto;
    width: 100%;
    max-width: 100%;
    position: relative;
    padding: 8px;

    img,
    .image {
      margin-bottom: 0;
    }

    h2 {
      margin-top: 0;
      margin-bottom: 10px;
    }

    span {
      display: block;
    }

    &.animation {
      // Initial State (before web fonts are loaded, before animation)

      &.first,
      &.second,
      &.third,
      &.fourth {
        @extend .animated;

        opacity: 0; /* stylelint-disable-line declaration-empty-line-before */
        transform: translateY(40px);
      }

      // Activated State (web fonts are loaded & "activate-animation" class exists)
      .wf-active &.animate,
      .wf-inactive &.animate {
        @extend .fadeIn;

        transition-timing-function: ease;
        transform: translateY(0);

        &.first {
          transition-duration: 1s;
          animation-delay: 0.2s;
        }

        &.second {
          transition-duration: 2s;
          animation-delay: 0.4s;
        }

        &.third {
          transition-duration: 3s;
          animation-delay: 0.6s;
        }

        &.fourth {
          transition-duration: 4s;
          animation-delay: 0.8s;
        }
      }
    }
  }

  &.offset-top-bar {
    margin-top: 112px;
  }

  // Center-aligned option
  &.center-aligned {
    justify-content: center;
  }

  // Specific components

  &.portfolio-features {
    > li {
      width: 20%;
      max-width: 20%;

      &.animation {
        // Initial State (before web fonts are loaded, before animation)

        &.first,
        &.second,
        &.third,
        &.fourth {
          @extend .animated;

          opacity: 0; /* stylelint-disable-line declaration-empty-line-before */
          transform: translateY(40px);
        }

        // Activated State (web fonts are loaded & "activate-animation" class exists)
        .wf-active &.animate,
        .wf-inactive &.animate {
          @extend .fadeIn;

          transition-timing-function: ease;
          transform: translateY(0);

          &.first {
            transition-duration: 1s;
            animation-delay: 0.2s;
          }

          &.second {
            transition-duration: 2s;
            animation-delay: 0.4s;
          }

          &.third {
            transition-duration: 3s;
            animation-delay: 0.6s;
          }

          &.fourth {
            transition-duration: 4s;
            animation-delay: 0.8s;
          }
        }
      }
    }

    // Responsive methods
    @mixin respond-1 {
      max-width: 460px;
      margin-left: auto;
      margin-right: auto;

      > li {
        width: 50%;
        max-width: 50%;
      }
    }

    @mixin respond-2 {
      > li {
        width: 50%;
        max-width: 50%;
      }
    }

    // Responsive breakpoints
    @include mq($until: mq-8) {
      @include respond-1;
    }
    @include mq($until: mq-4) {
      @include respond-2;
    }
  }

  &.range-of-services {
    > li {
      width: 25%;
      max-width: 25%;
    }

    // Responsive methods
    @mixin respond-1 {
      max-width: 460px;
      margin-left: auto;
      margin-right: auto;

      > li {
        width: 50%;
        max-width: 50%;
      }
    }

    @mixin respond-2 {
      max-width: 320px;
      > li {
        width: 100%;
        max-width: 100%;
      }
    }

    // Responsive breakpoints
    @include mq($until: mq-10) {
      @include respond-1;
    }
    @include mq($until: mq-5) {
      @include respond-2;
    }
  }

  &.showcase-thumb {
    > li {
      width: 33.33%;
      max-width: 420px;
      text-align: left;
      h4 {
        margin-bottom: 5px;
      }
      a {
        color: $color-general-text;
      }
      .showcase-info {
        position: relative;
        padding: 20px;
        background-color: $color-white;
        min-height: 180px;
        .color-bar {
          position: absolute;
          height: 4px;
          left: 0;
          bottom: 0;
          z-index: 1;
          width: 0%;
          background-color: $color-primary;
          transition: width 0.3s ease-out;
        }
      }
    }
    li:hover {
      .showcase-info {
        .color-bar {
          width: 100%;
        }
      }
    }

    // Responsive methods
    @mixin respond-1 {
      > li {
        width: 100%;
        max-width: 420px;
        min-width: 320px;
      }
    }

    @mixin respond-2 {
      > li {
        width: 100%;
        max-width: 420px;
        min-width: 280px;
      }
    }

    // Responsive breakpoints
    @include mq($until: mq-10) {
      @include respond-1;
    }
    @include mq($until: mq-5) {
      @include respond-2;
    }
  }

  &.dim-image-on-hover {
    > li {
      img {
        opacity: 1;
        transition: opacity 0.5s ease-in-out;
      }
      &:hover {
        img {
          opacity: 0.5;
        }
      }
    }
  }
}
