.wonderwall {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    list-style: none;

    > .tile {
        flex: 1 1 auto;
        width: 100%;
        max-width: 100%;
        position: relative;
        cursor: pointer;


        img {
            position: relative;
            margin-bottom: 0;
            width: 100%;
        }

        .content {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            @extend %flexbox-center-child;

            > .content-wrapper {
                padding: 16px;
                text-align: center;

                > * {
                    display: block;
                    color: $color-white;
                }

                span {
                    font-size: 1.5rem;
                    margin-bottom: 8px;
                    &.box-text {
                        position: absolute;
                        bottom:5px;
                        left:0;
                        background-color: $color-black;
                        padding: 5px 10px 5px 10px;
                    }
                }

                h2 {
                    margin: 0;
                    text-transform: uppercase;
                }
            }
        }
    }

    // Class modifiers

    &.show-content-on-hover {
        > .tile {
            .content {
                opacity: 0;
                transform: translate3d(0, 50px, 0);
                transition: all 0.5s ease-in-out;
            }

            &:hover {
                .content {
                    opacity: 1;
                    @extend %flexbox-center-child;
                    transform: translate3d(0, 0, 0);
                }
            }

        }
    }

    &.dim-image-on-hover {
        background-color: $color-black;

        > .tile {
            img {
                opacity: 1.0;
                transition: opacity 0.5s ease-in-out;
            }

            &:hover {
                img {
                    opacity: 0.5;
                }
            }
        }
    }

    &.color-image-on-hover {
        > .tile {
            img {
                filter: grayscale(100%);
            }

            &:hover {
                img {
                    filter: grayscale(10%);
                }
            }
        }
    }

    &.image-zoom-on-hover {
        > .tile {
            overflow: hidden;
            img {
                max-width: 100%;
                transition: all 0.3s ease-in-out;
            }

            &:hover {
                img {
                    transform: scale(1.1);
                }
            }
        }
    }

    &.center-aligned {
        justify-content: center;
    }



    // Specific components

    &.portfolios {
        > .tile {
            width: 25%;
            max-width: 25%;
        }

        // Responsive methods
        @mixin respond-1 {
            > .tile {
                width: 50%;
                max-width: 50%;
            }
        }

        @mixin respond-2 {
            > .tile {
                width: 100%;
                max-width: 100%;
                margin-bottom: 20px;
            }
        }

        // Responsive breakpoints
        @include mq($until: mq-11)      { @include respond-1; }
        @include mq($until: mq-6)       { @include respond-2; }
    }

    &.gallery {
        > .tile {
            width: 50%;
            max-width: 50%;
        }

        // Responsive methods
        @mixin respond-1 {
            > .tile {
                width: 100%;
                max-width: 100%;
            }
        }

        // Responsive breakpoints
        @include mq($until: mq-6)       { @include respond-1; }
    }

    &.clients {
        > .tile {
            width: 25%;
            max-width: 25%;
        }

        // Responsive methods
        @mixin respond-1 {
            > .tile {
                width: 33.33%;
                max-width: 33.33%;

                img {
                    width: auto;
                }
            }
        }

        @mixin respond-2 {
            > .tile {
                width: 50%;
                max-width: 50%;
            }
        }

        @mixin respond-3 {
            > .tile {
                width: 100%;
                max-width: 100%;
            }
        }

        // Responsive breakpoints
        @include mq($until: mq-10)      { @include respond-1; }
        @include mq($until: mq-6)       { @include respond-2; }
        @include mq($until: mq-4)       { @include respond-3; }
    }

    &.side-by-side {
        > .tile {
            margin-bottom: 20px;
        }
        // Responsive methods
        @mixin respond-1 {
            > .tile {
                width: 50%;
                max-width: 50%;
            }
        }

        @mixin respond-2 {
            > .tile {
                margin: 0 auto;
                margin-bottom: 20px;
                width: 100%;
                max-width: 452px;
            }
        }

        @mixin respond-3 {
        }

        // Responsive breakpoints
        @include mq($until: mq-10)      { @include respond-1; }
        @include mq($until: mq-6)       { @include respond-2; }
        @include mq($until: mq-4)       { @include respond-3; }
    }

}
