// Some vendor overrides

.qtip{
    font-size:inherit;
    line-height:inherit;
}

.qtip-content{
    padding: 0;
}

.qtip-light{
    background-color: $color-semi-light-grey;
    border-color: $color-general-secondary-text;
    color: inherit;
}



// Custom tooltip CSS

.tooltip-content{
    display: none;
    padding:16px;
    
    .field{
        margin-bottom:16px;
    }
}
