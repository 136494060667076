.error-banner {
    text-align: center;
    
    h1 {
        @extend .standard-vertical-gap;
        line-height: 1.0;
        font-size: 3.0rem;
        em {
            font-size: 10.0rem;
            color: $color-inverted-text;
            display: block;
        }
    }

    .details {
        @extend .standard-vertical-gap;
        > span {
            display: block;
        }
    }
}
