.accordion {
    button.accordion {
        background-color: inherit;
        cursor: pointer;
        padding: 10px 20px;
        width: 100%;
        text-align: left;
        font-size:1.8rem;
        line-height:1.5;
        color:$color-general-text;
        transition: 0.2s;
        margin-bottom: 0px;
        border: 1px solid $color-general-separator;
    }

    button.accordion:hover, button.accordion.active {
        background-color: $color-primary;
        color: $color-white;
    }

    button.accordion:after {
        content: '\002B';
        font-weight: bold;
        float: right;
        margin-left: 20px;
    }

    button.accordion.active:after {
        content: "\2212";
    }

    div.panel {
        text-align: left;
        padding-top: 0;
        margin-bottom: 20px;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.2s ease-out;
        p {
            padding-top: 20px;
            padding-left: 20px;
            padding-right: 20px;
            margin-bottom: 0;
        }
    }
}

// Responsive methods
@mixin respond-1 {
}

@mixin respond-2 {

}

@mixin respond-3 {

}

// Responsive breakpoints
@include mq($until: mq-12)      { @include respond-1; }
@include mq($until: mq-10)      { @include respond-2; }
@include mq($until: mq-4)       { @include respond-3; }
