footer {
    background-color: $color-white;

    nav {
        padding-top: 20px;
        ul {
            padding: 0;
            list-style: none;
            font-size:1.3rem;
            > li {
                display:inline;
                margin: 0 10px;
                a {
                    line-height: 24px;
                    transition: color 0.3s linear;
                    color: $color-general-text;

                    &:hover {
                        color: $color-secondary;
                    }

                }
            }
        }
    }

    .bs-footer-logo {
        margin: 0 auto;
        padding-top:60px;
    }

    .social-network {
        margin-top: 10px;
        padding-top: 0px;
        margin-bottom: 20px;
        display: block;
        .bs-icon {
            font-size: 36px;
            margin: 0 3px;
        }
        a {
            color: $color-general-gery;
             -webkit-transition: color .5s linear;
             -moz-transition: color .5s linear;
             -ms-transition: color .5s linear;
             -o-transition: color .5s linear;
             transition: color .5s linear;
             display: inline-block;
        }
        a:hover {
            color: $color-primary;
        }
    }

    .language {
        display: block;
        padding-bottom: 50px;
        a {
            color: $color-general-gery;
            margin: 0 10px;
        }
        a:hover {
            color: $color-secondary;
        }
        a.active {
            color: $color-primary;
        }
    }

    .footer-copyright {
        font-size:1.3rem;
        margin-bottom: 10px;
        a {
            color: $color-general-text;
        }
    }
    .bs-group {
        padding-bottom: 40px;
        a {
            display: inline-block;
        }
        img {
            display: block;
        }
    }
}


// Responsive methods
@mixin respond-1 {

}

@mixin respond-2 {

}

@mixin respond-3 {
    .footer {
        .bs-footer-logo {
            width: 280px;
        }
    }
}

// Responsive breakpoints
@include mq($until: mq-12)      { @include respond-1; }
@include mq($until: mq-10)      { @include respond-2; }
@include mq($until: mq-4)       { @include respond-3; }
