.mm-menu {
    background-color: $color-primary;
    color: $color-white;
    .mm-panels {
        .mm-panel {
        position: absolute;
        top:40px;
        left:60px;
        right:60px;
        }
        .mm-navbar {
            .mm-title{
                color: $color-white;
            }
        }
    }
    .mm-listview {
        font-size: 3.0rem;
        line-height: 30px;
        li {
            padding-top: 20px;
            padding-bottom: 20px;
        }
        li:after {
            border: 0;
        }
        li:nth-last-child(5) {
            border-bottom: 1px dotted;
            border-color: $color-primary-light;
            padding-bottom: 40px;
        }
        li:nth-last-child(4) {
            padding-top: 40px;
        }
        a {
            color: $color-white;
            padding: 0;
        }
        a:hover {
            color: $color-primary-light;
        }
    }

    // Responsive levels & styles
    @mixin respond-1 {
        .mm-listview {
            font-size: 1.6rem;
            line-height: 30px;
        }
    }


    // Responsive breakpoints
    @include mq($until: mq-6)  { @include respond-1; }
}
.mm-menu .mm-listview > li.mm-selected > a:not(.mm-next), .mm-menu .mm-listview > li.mm-selected > span
{
    background: rgba(255, 255, 255, 0);
}
