.animated-icon {
    margin: 0 auto;
    width: 100px;
    height: 100px;
    //background:url('../img/icon-scroll-down.svg');
    transform: scale(1);
    animation: play 1s steps(6) infinite;
}
@keyframes play {
    from { background-position: 0px; }
    to { background-position: -600px; }
}
