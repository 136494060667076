.action-buttons {
  @extend %clearfix;
  @extend .standard-vertical-gap;
  position: relative;
  display: block;
  box-sizing: border-box;

  button,
  input[type="submit"],
  input[type="reset"],
  input[type="button"],
  a[role="button"] {
    float: left;
    display: block;
    margin-right: 8px;
    margin-bottom: 8px;
    &:last-child {
      margin-right: 0;
    }
  }

  // Responsive methods
  @mixin respond-1 {
    button,
    input[type="submit"],
    input[type="reset"],
    input[type="button"],
    a[role="button"] {
        width: 100%;
        min-width: 0;
    }
  }
  
  // Responsive breakpoints
  @include mq($until: mq-3)      { @include respond-1; }
}
