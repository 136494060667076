.beambrand {
    display: inline-block;
    vertical-align: bottom;
    overflow: hidden;
    text-indent: -9999px;
    font-family: $font-family-en-primary;

    &:before {
        float: left;
        text-indent: 0;
        color: $color-primary;
        content: "Beam";
    }
    &:after {
        float: left;
        text-indent: 0;
        color: $color-bs-grey;
        content: "Brand";
    }

    // vertical align text-bottom mode
    &.vertical-align-text-bottom {
        vertical-align: text-bottom;
    }
}
