.achievements {
    @extend .standard-vertical-gap;
    ul {
        display: inline-block;
        padding: 0;
        list-style: none;
        @extend %clearfix;
        
        > li {
            float: left;
            margin-left: 24px;
            margin-right: 24px;
            text-align: left;
            @extend .standard-vertical-gap;
            
            span {
                display: block;
                padding-left: 60px;
                background-repeat: no-repeat;
                
                strong {
                    display: block;
                    font-size: 4.6rem;
                    font-weight: 900;
                    line-height: 1.0;
                    color: $color-primary;
                }
            }
        }
    }
    
    &.animation {
        // Initial state (before web fonts are loaded, before the animation)
        & {
            ul > li             { @extend .animated; opacity: 0; }
        }
        
        // Activated state (when web fonts are loaded, "animate" class added)
        .wf-active &.animate, .wf-inactive &.animate {
            ul > li             { @extend .fadeIn; animation-delay: 0s; }
        }
    }

    // Responsive methods
    @mixin respond-1 {
        ul {
            > li {
                float: none;
                @include standard-vertical-gap;
            }
        }
    }
    
    // Responsive breakpoints
    @include mq($until: mq-5)       { @include respond-1; }
}