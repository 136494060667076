// Secondary buttons
button,
input[type="submit"],
input[type="reset"],
input[type="button"],
a[role="button"] {
  &.secondary {
    background-color: inherit;
    color: $color-general-text;
    border: 1px solid $color-general-separator;

    &:hover {
      color: $color-primary;
      border: 1px solid $color-primary;
    }
    &:focus,
    &:active {
      color: $color-primary;
      border: 1px solid $color-primary;
    }
  }

  // Inverted theme primary option
  .inverted-theme-primary & {
    background-color:$color-primary-light;
    color:$color-white;
    &:hover{
      background-color:$color-secondary;
    }
    &:focus, &:active{
      background-color:$color-secondary;
    }
  }
}

//rounded-button
button,
input[type="submit"],
input[type="reset"],
input[type="button"],
a[role="button"] {
  &.rounded {
    background: none;
    border: 1px solid $color-general-separator;
    color: $color-general-text;
    border-radius: 25px;
    margin-bottom: 10px;

    &:hover {
      color: $color-primary;
      border: 1px solid $color-primary;
    }

    &:focus,
    &:active {
      color: $color-secondary;
      border: 1px solid $color-secondary;
    }

    &.home-banner-button {
      margin: 40px;
      color: $color-white;
    }

    &.home-banner-button:hover {
      color: $color-white;
      border: 1px solid $color-white;
    }
  }
}

// Flat buttons
button,
input[type="submit"],
input[type="reset"],
input[type="button"],
a[role="button"] {
  &.flat {
    background: none;
    color: $color-primary;

    &:hover {
      color: $color-general-link-hover;
    }
    &:focus,
    &:active {
      background-color: $color-general-background-hover;
    }

    &.secondary {
      color: $color-general-text;

      &:hover {
        color: $color-general-link-hover;
      }
      &:focus,
      &:active {
        background-color: $color-general-background-hover;
      }
    }
  }
}

// Icon buttons
button,
input[type="submit"],
input[type="reset"],
input[type="button"],
a[role="button"] {
  &.icon {
    @include size(34px);
    font-size: 2.4rem;
    border-radius: 50%;
    padding: 0;
    min-width: 0;
    text-align: center;
  }
}

// Icon  with caption buttons
button,
input[type="submit"],
input[type="reset"],
input[type="button"],
a[role="button"] {
  &.icon-caption {
    padding: 0 8px;
    min-width: 0;
    &:focus,
    &:active {
      background: none;
    }

    // For material icons or other font icons
    > i {
      display: inline-block;
      font-size: 1.8rem;
      line-height: 34px;
      vertical-align: top;
    }
  }
}
