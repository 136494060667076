/*
.card {
  @extend %elevation-card;
  @extend .standard-vertical-gap;

  background-color: $color-white;
  border: 1px solid $color-general-separator;
  position: relative;
  width: 100%;

  > .content-wrapper {
    padding: 32px;

    + .content-wrapper {
      border-top: 1px solid $color-general-separator;
    }
  }
}
*/
