.home-banner{
    position:relative;
    text-align: center;

    > .placeholder{
        .image{
            width: 100%;
            min-height: 400px;
            height: 0;
            overflow:hidden;
            background-position: top center;
            background-repeat: no-repeat;
            background-size: cover;
        }
        //&.full-screen {
        //    height: calc(100vh);
        //}
    }

    > .content{
        position:absolute;
        top:0;
        left:0;
        right:0;
        bottom:0;
        display:block;

        > .content-wrapper{
            @extend %flexbox-center-child;
            position:relative;
            margin:auto;
            max-width:800px;
            height:100%;
        }

        h1 {
            margin: auto;
            color: $color-inverted-heading;
            //text-transform: uppercase;
            font-weight: 300;
        }
        p {
            margin: auto;
            color: $color-inverted-text;
            font-size: 2.4rem;
            text-align: center;
        }
    }

    // Class modifiers

    &.animation {
        // Initial State (before web fonts are loaded, before animation)
        & {
            .placeholder        { @extend .animated; opacity: 0; }
            h1                  { @extend .animated; opacity: 0; }
        }

        // Activated State (web fonts are loaded & "activate-animation" class exists)
        .wf-active &.animate, .wf-inactive &.animate {
            .placeholder        { @extend .fadeIn; animation-delay: 0.1s; }
            h1                  { @extend .fadeIn; animation-delay: 1.2s; }
        }
    }

    // Thick minimum height mode
    &.thick-minimum-height {
        > .placeholder{
            .image{
                min-height: 500px;
            }
        }
    }

    // Default maximum height mode
    &.default-maximum-height {
        > .placeholder{
            max-height: 500px;
            overflow: hidden;
        }
    }

    // Parallax mode
    &.parallax {
        > .placeholder{
            .image{
                background-attachment: fixed;
            }
        }
    }

    // Offset the content from the top bar navigation
    &.offset-top-bar {
        > .content {
            top: 113px;
        }
    }

    // Responsive levels & styles
    @mixin respond-1 {
        > .content{
            h1, p {
                max-width: calc(100% - 108px - 108px);
            }
        }
    }

    @mixin respond-2 {
        > .content{
            h1, p {
                max-width: calc(100% - 24px - 24px);
            }
            p {
                display: none;
            }
        }
        &.offset-top-bar {
            > .content {
                margin-top: 80px;
            }
        }
    }

    @mixin respond-3 {
        > .content{
            h1 {
                font-size: 3.0rem;
            }
        }
    }

    // Responsive breakpoints
    @include mq($until: mq-12)  { @include respond-1; }
    @include mq($until: mq-10)  { @include respond-2; }
    @include mq($until: mq-6)   { @include respond-3; }
}
