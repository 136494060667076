// Font families

// Font files should be included in the <head> to optimize load performance
// For East-Asian Web Fonts, consider visiting https://fonts.google.com/earlyaccess

// Font faces
// https://developer.mozilla.org/en-US/docs/Web/CSS/font-weight (Font-weight calculations)

// Multilingual
// https://webdevstudios.com/2015/01/29/creating-a-multilingual-site/

// Font families we have included
$font-family-roboto: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-family-noto-sans-tc: 'Helvetica Neue', Helvetica, Arial, 'Noto Sans TC', 'PingFang TC', 'Microsoft JhengHei', '蘋果儷中黑', '微軟正黑體', sans-serif;
$font-family-noto-sans-sc: 'Helvetica Neue', Helvetica, Arial, 'Noto Sans SC', 'Hiragino Sans GB', 'Microsoft YaHei', '微软雅黑体', 'WenQuanYi Micro Hei', sans-serif;

// Font families to be used in stylesheets
$font-family-en-primary: $font-family-roboto;
$font-family-zh-TW-primary: $font-family-noto-sans-tc;
$font-family-zh-CN-primary: $font-family-noto-sans-sc;
